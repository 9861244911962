/* unvisited link */
a:link {
  color: #010101;
}

/* visited link */
a:visited {
  color: #010101;
}

/* mouse over link */
a:hover {
  color: blue;
}

/* selected link */
a:active {
  color: #010101;
}
